<template>
    <div v-if="isDataLoaded" class="list-gruppen-spiele">
        <div v-if="spieleProTisch.length == 0 && selectedGruppenIDs.length == 0 && selectedTische.length == 0">
            <md-empty-state
                md-icon="sports_bar"
                :md-label="lang.turnierplan.emptyStateLabel"
                :md-description="lang.turnierplan.emptyStateDescription">
            </md-empty-state>
        </div>
        <div v-else>
            <div v-if="isDataLoaded" class="filter-container">
                <md-field class="gruppeField">
                    <label for="gruppe">{{lang.listGruppenSpiele.labelGruppe}}</label>
                    <md-select v-model="selectedGruppenIDs" :name="lang.listGruppenSpiele.labelGruppe" id="gruppe" md-dense multiple>
                        <md-option v-for="gruppe in gruppen" :key="gruppe.gruppenID" :value=gruppe.gruppenID>{{gruppe.gruppenName}}</md-option>
                    </md-select>
                </md-field>
                <md-field class="tischField">
                    <label for="tisch">{{lang.listGruppenSpiele.labelTisch}}</label>
                    <md-select v-model="selectedTische" :name="lang.listGruppenSpiele.labelTisch" id="tisch" md-dense multiple>
                        <md-option v-for="tischNummer in tische" :key="tischNummer" :value="tischNummer">{{tischNummer}}</md-option>
                    </md-select>
                </md-field>
            </div>
            <div class="list-spiele-container">
                <ul class="ul-unstyled spiele-tisch-list">
                    <li v-for="tisch in spieleProTisch" :key="tisch.tischNummer">
                        <span class="tischHeadline" v-if="tisch.spiele.length != 0">
                                {{lang.spieleList.tischHeadline}} {{tisch.tischNummer}}
                        </span>
                        <ul class="ul-unstyled spiele-list" v-if="tisch.spiele.length != 0">
                            <li v-for="spiel in tisch.spiele" :key="spiel.spielID">
                                <div v-if="spiel.spielErgebnisse.length != 0">
                                    <div class="spiel-item card">
                                        <span class="spielNummer">{{spiel.tischSpielNummer}}</span>
                                        <span class="gruppenName"><i class="fas fa-users"></i> {{spiel.gruppenSpiele[0].gruppe.gruppenName}}</span>
                                        <div class="teamName team1">
                                            <span :class="spiel.spielErgebnisse[0].gewonnen ? 'gewonnen' : ''">{{getTeamNameByTeamID(spiel.spielErgebnisse[0].spielTeamID.teamID)}}</span>
                                        </div>
                                        <div class="punkte">
                                            <span :class="spiel.spielErgebnisse[0].gewonnen ? 'gewonnen' : ''">{{spiel.spielErgebnisse[0].erreichtePunkte}}</span>
                                            <span>-</span>
                                            <span :class="spiel.spielErgebnisse[1].gewonnen ? 'gewonnen' : ''">{{spiel.spielErgebnisse[1].erreichtePunkte}}</span>
                                        </div>
                                        <div class="teamName team2">
                                            <span :class="spiel.spielErgebnisse[1].gewonnen ? 'gewonnen' : ''">{{getTeamNameByTeamID(spiel.spielErgebnisse[1].spielTeamID.teamID)}}</span>
                                        </div>
                                        <div v-if="!isTurnierBeendet && !isFinalphaseGestartet" class="button-group-game">
                                            <div v-if="isSpielerTurnierAdmin && !(spiel.spielErgebnisse[0].spielTeamID.teamID == teamIDByPlayer || spiel.spielErgebnisse[1].spielTeamID.teamID == teamIDByPlayer)">
                                                <md-button class="md-primary md-round md-raised md-filled md-secondary" 
                                                    @click="showDialogSaveSpielstand=true,
                                                            setNewSpielstand(spiel.spielID,
                                                                spiel.spielErgebnisse[0].erreichtePunkte,
                                                                spiel.spielErgebnisse[1].erreichtePunkte,
                                                                spiel.spielErgebnisse[0].spielTeamID.teamID,
                                                                spiel.spielErgebnisse[1].spielTeamID.teamID)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </md-button>
                                            </div>
                                            <div v-if="spiel.spielErgebnisse[0].spielTeamID.teamID == teamIDByPlayer || spiel.spielErgebnisse[1].spielTeamID.teamID == teamIDByPlayer || (isSpielerTurnierAdmin && teamIDByPlayer == 0)">
                                                <div v-if="spiel.spielErgebnisse[0].gewonnen || spiel.spielErgebnisse[1].gewonnen">
                                                    <md-button class="md-primary md-round md-raised md-filled md-secondary" 
                                                        v-if="!isSpielerTurnierAdmin"
                                                        @click="showDialogSaveSpielstand=true,
                                                                setNewSpielstand(spiel.spielID,
                                                                    spiel.spielErgebnisse[0].erreichtePunkte,
                                                                    spiel.spielErgebnisse[1].erreichtePunkte,
                                                                    spiel.spielErgebnisse[0].spielTeamID.teamID,
                                                                    spiel.spielErgebnisse[1].spielTeamID.teamID)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </md-button>
                                                </div>
                                                <md-button v-else @click="spielen(spiel.spielID)" class="md-primary md-round md-raised md-filled md-secondary">
                                                    <span>{{lang.spieleList.buttonTextSpielen}}</span>
                                                </md-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <md-dialog :md-active.sync="showDialogSaveSpielstand" :md-fullscreen=false>
            <div class="dialog-content dialog-spielstand">
                <p class="headline p-unstyled">{{lang.spielstandForm.spielStandEditHeadline}}</p>
                <p class="message p-unstyled">{{lang.spielstandForm.addSpielStandMessage}}</p>
                <p class="headline2">{{lang.spielstandForm.spielStandHeadline2}}</p>
                <form @submit.prevent="editSpielStand()" id="addSpielstandForm">
                    <md-field :class="getValidationClass('erreichtePunkteTeam1')">
                        <label>{{getTeamNameByTeamID(spielstandNew.teamID1)}}</label>
                        <md-input @blur="$v.spielstandNew.erreichtePunkteTeam1.$touch()" type="number" step="1" :min="$v.spielstandNew.erreichtePunkteTeam1.$params.between.min" 
                            :max="$v.spielstandNew.erreichtePunkteTeam1.$params.between.max" id="erreichtePunkteTeam1" v-model="spielstandNew.erreichtePunkteTeam1" :name="lang.spielstandForm.erreichtePunkteTeam1Label"></md-input>
                        <md-icon v-if="isFieldInvalid('erreichtePunkteTeam1')" class="color-invalid">close</md-icon>
                        <span class="md-error" v-if="!$v.spielstandNew.erreichtePunkteTeam1.required">{{lang.spielstandForm.erreichtePunkteTeam1Required}}</span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam1.between">
                            {{$v.spielstandNew.erreichtePunkteTeam1.$params.between.min}}-{{$v.spielstandNew.erreichtePunkteTeam1.$params.between.max}} {{lang.spielstandForm.erreichtePunkteTeam1Between}}
                        </span>
                    </md-field>
                    <md-field :class="getValidationClass('erreichtePunkteTeam2')">
                        <label>{{getTeamNameByTeamID(spielstandNew.teamID2)}}</label>
                        <md-input @blur="$v.spielstandNew.erreichtePunkteTeam2.$touch()" type="number" step="1" :min="$v.spielstandNew.erreichtePunkteTeam2.$params.between.min" 
                            :max="$v.spielstandNew.erreichtePunkteTeam2.$params.between.max" id="erreichtePunkteTeam2" v-model="spielstandNew.erreichtePunkteTeam2" :name="lang.spielstandForm.erreichtePunkteTeam2Label"></md-input>
                        <md-icon v-if="isFieldInvalid('erreichtePunkteTeam2')" class="color-invalid">close</md-icon>
                        <span class="md-error" v-if="!$v.spielstandNew.erreichtePunkteTeam2.required">{{lang.spielstandForm.erreichtePunkteTeam2Required}}</span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam2.between">
                            {{$v.spielstandNew.erreichtePunkteTeam2.$params.between.min}}-{{$v.spielstandNew.erreichtePunkteTeam2.$params.between.max}} {{lang.spielstandForm.erreichtePunkteTeam2Between}}
                        </span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam2.notSameValue">{{lang.spielstandForm.erreichtePunkteTeam2NotSameValue}}</span>
                    </md-field>
                    <md-dialog-actions>
                        <md-button @click="showDialogSaveSpielstand=false">{{lang.spielstandForm.buttonTextAbbrechen}}</md-button>
                        <md-button type="submit" class="md-primary">{{lang.spielstandForm.buttonTextAgree}}</md-button>
                    </md-dialog-actions>
                </form>
            </div>
        </md-dialog>
        <InformationModal/>
        <SubmitModal/>
    </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import {required, between, not, sameAs} from 'vuelidate/lib/validators'
import InformationModal from '../Utils/InformationModal'
import SubmitModal from '../Utils/SubmitModal'
import { VueOfflineMixin } from 'vue-offline'

export default {
    name: 'ListGruppenSpieleByTurnier',
    props: ['turnierID'],
    mixins: [VueOfflineMixin],
    components: {
        InformationModal,
        SubmitModal
    },
    data() {
        return {
            selectedGruppenIDs: [],
            selectedTische: [],
            showDialogSaveSpielstand: false,
            spielstandNew: {
                spielID: 0,
                teamID1: 0,
                teamID2: 0,
                erreichtePunkteTeam1: 0,
                erreichtePunkteTeam2: 0
            }
        }
    },
    validations: {
        spielstandNew: {
            erreichtePunkteTeam1: {
                required,
                between: between(0, 10)
            },
            erreichtePunkteTeam2: {
                required,
                between: between(0, 10),
                notSameValue: not(sameAs('erreichtePunkteTeam1'))
            }
        }
    },
    created() {
        this.selectedGruppenIDs = this.getLocalStorageVariable('selectedGruppenIDs');
        this.selectedTische = this.getLocalStorageVariable('selectedTische');
    },
    watch: {
        selectedGruppenIDs(newSelectionGruppen) {
            this.setLocalStorageVariable("selectedGruppenIDs", newSelectionGruppen);
        },
        selectedTische(newSelectionTische) {
            this.setLocalStorageVariable("selectedTische", newSelectionTische);
        },
    },
    methods: {
        getTeamNameByTeamID(teamID) {
            const teamData = store.getters.getTeamByTeamID(teamID);
            return teamData == null || teamData.length == 0 ? "" : teamData[0].team.teamName;
        },
        getValidationClass(fieldName) {
            return {
                'md-invalid': this.isFieldInvalid(fieldName)
            }
        },
        isFieldInvalid(fieldName) {
            const field = this.$v.spielstandNew[fieldName]
            if (field) {
                return field.$invalid && field.$dirty;
            }
        },
        setNewSpielstand(spielID, erreichtePunkteTeam1, erreichtePunkteTeam2, teamID1, teamID2) {
            this.spielstandNew.spielID = spielID,
            this.spielstandNew.teamID1 = teamID1,
            this.spielstandNew.teamID2 = teamID2,
            this.spielstandNew.erreichtePunkteTeam1 = erreichtePunkteTeam1,
            this.spielstandNew.erreichtePunkteTeam2 = erreichtePunkteTeam2
        },
        async editSpielStand() {
            if (this.isOffline) {
                this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
            } else {
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.$v.$reset();
                    await store.dispatch('putSpielergebnis', this.spielstandNew, this.turnierID)
                        .then(result => {
                            if (result.data != null) {
                                store.dispatch('getSpieleByTurnierID', this.turnierID);
                                this.showDialogSaveSpielstand = false;
                                this.showDialogActive(true, this.lang.spielstandForm.editSuccessHeadline, "");
                                setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                            } else {
                                this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spiele.apiReponseErrorMessage);
                            }
                        })
                        .catch(error => {
                            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spiele.apiReponseErrorMessage);
                        });
                }
            }
        },
        showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
            store.commit("SET_SHOW_SUBMIT_MODAL", {
                show: true,
                success: formSuccess,
                headline: formSuccessHeadline,
                message: formSuccessMessage
            });
        },
        spielen(spielID) {
            if (this.isOffline) {
                this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
            } else {
                if (this.isTurnierStarted() || this.isSpielerTurnierAdmin) {
                    router.push({ name: 'PlayGame', params: { spielID: spielID, turnierID: this.turnierID }});
                }
                else {
                    store.commit("SET_SHOW_INFORMATION_DIALOG", {
                        show: true,
                        headline: this.lang.listGruppenSpiele.informationModalHeadline,
                        message: this.lang.listGruppenSpiele.informationModalMessage
                    });
                }
            }
        },
        isTurnierStarted() {
            const dateNow = new Date();
            const turnierBeginnUhrzeitSplit = store.getters.getTurnier.startZeit.split(":");
            const turnierBeginnDatumSplit = store.getters.getTurnier.datum.split("-");
            const turnierBeginn = new Date(turnierBeginnDatumSplit[0], turnierBeginnDatumSplit[1]-1, turnierBeginnDatumSplit[2],
                                            turnierBeginnUhrzeitSplit[0], turnierBeginnUhrzeitSplit[1], turnierBeginnUhrzeitSplit[2], 0);
            return (turnierBeginn - dateNow) < 0;
        },
        setLocalStorageVariable(localStorageVariableName, data) {
            const newData = {
                turnierID: this.turnierID,
                data: data
            }
            let newLocalStorageData = []
            if (localStorage.getItem(localStorageVariableName)) {
                const oldLocalStorageData = JSON.parse(localStorage.getItem(localStorageVariableName));
                if (Array.isArray(oldLocalStorageData)) {
                    const currentTurnierIndex = oldLocalStorageData.findIndex(t => t.turnierID == this.turnierID);
                    if (currentTurnierIndex != -1) {
                        oldLocalStorageData[currentTurnierIndex] = newData;
                    } else {
                        oldLocalStorageData.push(newData);
                    }
                    newLocalStorageData = oldLocalStorageData;
                }
            } else {
                newLocalStorageData.push(newData);
            }
            localStorage.setItem(localStorageVariableName, JSON.stringify(newLocalStorageData));
        },
        getLocalStorageVariable(localStorageVariableName) {
            if (localStorage.getItem(localStorageVariableName)) {
                try {
                    const localStorageDataArray = JSON.parse(localStorage.getItem(localStorageVariableName));
                    if (Array.isArray(localStorageDataArray)) {
                        const localStorageDataArrayByTurnierID = localStorageDataArray.filter(t => t.turnierID == this.turnierID);
                        if (localStorageDataArrayByTurnierID.length == 1) {
                            return localStorageDataArrayByTurnierID[0].data;
                        }
                    } else {
                        localStorage.removeItem(localStorageVariableName);
                        return [];
                    }
                } catch(e) {
                    localStorage.removeItem(localStorageVariableName);
                    return [];
                }
            }
            return [];
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        spielerID() {
            return store.getters.getSpielerID;
        },
        teamIDByPlayer() {
            return store.getters.getPlayerTeamNumber(this.spielerID);
        },
        spieleProTisch() {
            const spieleArray = store.getters.getGruppenSpieleByTischeAndGruppen(this.selectedGruppenIDs, this.selectedTische);
            const spieleArraySortedByTischID = spieleArray.sort((a,b) => a.tisch - b.tisch);
            let spieleNewStructure = [];
            let spieleTisch = [];
            let currentTisch = spieleArraySortedByTischID[0] != undefined ? spieleArraySortedByTischID[0].tisch : 1;

            spieleArraySortedByTischID.forEach((spiel, index, array) => {
                if (spiel.tisch != currentTisch) {
                    spieleNewStructure.push({
                        tischNummer: currentTisch,
                        spiele: spieleTisch
                        });
                    spieleTisch = [];
                    currentTisch = spiel.tisch;
                }
                spieleTisch.push(spiel);
                if (index === (array.length - 1)) {
                    spieleNewStructure.push({
                        tischNummer: currentTisch,
                        spiele: spieleTisch
                    });
                }
            });
            return spieleNewStructure;
        },
        gruppen() {
            return store.getters.getGruppenSimple;
        },
        tische() {
            return store.getters.getTische;
        },
        isDataLoaded() {
            return store.state.spiele.isDataLoaded;
        },
        isSpielerTurnierAdmin() {
            return store.getters.isSpielerTurnierAdmin(this.turnierID);
        },
        isTurnierBeendet() {
            return store.getters.isTurnierBeendet;
        },
        isFinalphaseGestartet() {
            return store.getters.isFinalphaseGestartet;
        },
    },
}
</script>

<style lang="scss" scoped>
</style>