<template>
    <div>
        <ListGruppenSpieleByTurnier :turnierID="turnierID"/>
    </div>
</template>

<script>
import ListGruppenSpieleByTurnier from '@/components/Spiele/ListGruppenSpieleByTurnier.vue'

export default {
    name: 'ListSpiele',
    props: ['turnierID'],
    components: {
        ListGruppenSpieleByTurnier
    }
}
</script>
